import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';
import Totsco from './img/totsco.svg';

const Section = styled.section`
  padding-top: ${boxPaddingMobile};
  width: 100%;

  @media ${mediaQuery.smallScreen} {
    padding-top: ${pagePaddingDesktop};
  }
`;

const Brand = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -2px;
`;

const GoBackLink = styled.button`
  display: block;
  margin-bottom: 45px;
  background-color: unset;
  border: none;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

const PageTitle = styled.h1`
  font-size: 48px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: -3px;

  @media ${mediaQuery.smallScreen} {
    font-size: 64px;
    line-height: 64px;
  }
`;

const SubTitle = styled.h2`
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -2px;
  margin-bottom: 0px;

  @media ${mediaQuery.smallScreen} {
    font-size: 40px;
    line-height: 40px;
  }
`;

const TotscoLogo = styled(Totsco)`
  height: 150px;
  width: 150px;
  align-self: center;
`;

export const S = {
  Section,
  GoBackLink,
  Brand,
  TitleContainer,
  PageTitle,
  SubTitle,
  TotscoLogo,
};
